<template>
  <section class="user-cv" v-if="!emptyCv || canEdit">
    <ContentBox>
      <div class="row">
        <div class="col-16">
          <h4 class="h4 font-component-headline mb-sm-xs">{{ $t('userCv.headline') }}</h4>
        </div>
        <div class="col-8  text-right">
          <InputSelect alt="small"
                       v-if="!$matchMedia.sm"
                       class="display-inlineblock  mr-xs"
                       :options="$t('userCv.inputs.language.options')"
                       v-model="showLanguage">
          </InputSelect>
          <Link class="-orange  mr-xs  display-inlineblock  valign-middle"
                v-if="!$matchMedia.sm && oldCvLink"
                @click.native="$emit('switchCvView')">{{ $t('userCv.buttons.switchOldVersion') }}</Link>
          <Button class="-outlined  -light  pos-relative  -no-padding  -size-32  valign-middle" @click.native="modalOpen" v-if="canEdit">
            <Icon class="-inline  -pos-absolute-center">
              <PlusSVG stroke="#0d2745" stroke-width="1" />
            </Icon>
          </Button>
        </div>
      </div>

      <div class="mb-xs" v-if="$matchMedia.sm">
        <InputSelect alt="small"
                     class="display-inlineblock  mr-xs"
                     :options="$t('userCv.inputs.language.options')"
                     v-model="showLanguage">
        </InputSelect>
      </div>

      <div class="mb-xs" v-if="$matchMedia.sm && oldCvLink">
        <Link class="-orange"
              @click.native="$emit('switchCvView')">{{ $t('userCv.buttons.switchOldVersion') }}</Link>
      </div>

      <ContentBox v-if="emptyCv">
        <div class="row">
          <div class="col-24 text-center">
            <span v-html="$t('userCv.subheadlines.empty')"></span>
            <div class="cta_new" :class="{ 'mt-s width-full': $matchMedia.md }" v-if="canEdit">
              <Icon v-if="!$matchMedia.md" class="_info-arrow -size-74 color-grey-200"><InfoArrowSVG></InfoArrowSVG></Icon>
              <div>
                <Button class="-orange" @click.native="modalOpen(blankEntry)">
                  {{ $t('userCv.buttons.add-first') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ContentBox>

      <template v-else>
        <transition-group name="list" tag="div">
          <ContentBox class="cv-entry" :class="{'mt-s': index > 0}" v-for="(entry, index) in sortedCv(cv_entries, entriesToShow)" :key="`key-${index}`">
            <div class="row">
              <div class="col-20">
                <h6 class="h6 mb-0 font-weight-medium">{{ entry.company }}
                  <span class="font-size-s  pl-xxs" v-if="entry.company_size">
                    {{ entry.company_size }} MA
                  </span>
                  <span class="font-size-s" v-if="entry.company_revenue">, {{ entry.company_revenue }} Mio.</span>
                </h6>
                <span>{{ $t(`userCv.inputs.employment.options.${entry.employment}`) }}: </span>
                <span v-if="entry.employment === 'mandate' && entry.mandate_title">{{ entry.mandate_title }}, </span>
                <span>{{ entry.position }}</span>
                <div class="when-where font-size-s font-weight-medium">
                  <div>
                    <Icon class="-inline -is-left">
                      <CalendarSVG/>
                    </Icon>
                    {{ entry.start ? dateToLocaleMonthAndYear(entry.start) : 'undefined' }} {{ entry.end ? '- ' + dateToLocaleMonthAndYear(entry.end) : '' }}
                  </div>
                  <div v-if="entry.location">
                    <Icon class="-inline -is-left">
                      <PlaceSVG/>
                    </Icon>
                    {{ entry.location }}
                  </div>
                </div>
              </div>
              <div class="col-4 text-right">
                <Button class="-outlined  -light  pos-relative  -no-padding  -size-32" @click.native="modalOpen(entry)" v-if="canEdit">
                  <Icon class="-inline  -pos-absolute-center">
                    <EditSVG />
                  </Icon>
                </Button>
              </div>
            </div>
            <div class="mb-xs" v-if="entry.company_description">
              <p class="font-size-xs text-uppercase color-blue-300">{{ $t('userCv.subheadlines.company-description') }}</p>
              <div class="description">
                {{ entry.company_description}}
              </div>
            </div>
            <template v-if="entry.description">
              <p class="font-size-xs text-uppercase color-blue-300">{{ $t('userCv.subheadlines.activities-results') }}</p>
              <div class="description">
                <div style="width: 100%;">
                  <div class="ProseMirror" v-html="entry.description"></div>
                </div>
              </div>
            </template>
            <div v-if="role === 'admin'" class="_admin-info text-right">
              <span v-if="!entry.show_in_pdf">{{ $t('userCv.inputs.show_in_pdf.info') }}</span>
              <span v-if="entry.only_admin">{{ $t('userCv.inputs.only_admin.info') }}</span>
            </div>
          </ContentBox>
        </transition-group>
        <div class="mt-s width-full text-center" v-if="displayShowMore">
          <Link class="-orange font-size-s" @click.native="showAll">
            {{ $t('userCv.buttons.show-more')}}
            <Icon class="-orange -inline -is-right">
              <ChevronSVG />
            </Icon>
          </Link>
        </div>
        <div class="mt-s width-full text-center" v-if="displayShowLess">
          <Link class="-orange font-size-s" @click.native="showLess">
            {{ $t('userCv.buttons.show-less')}}
            <Icon class="-orange -inline -is-right -rotate-180">
              <ChevronSVG />
            </Icon>
          </Link>
        </div>
      </template>
    </ContentBox>
    <Modal id="userCvModal"
           :title="$v.entryToEdit.id.$model === null ? $t('userCv.subheadlines.modal-new') :  $t('userCv.subheadlines.modal-edit')"
           v-if="canEdit"
           class="-thin"
           :disable="$v.$error"
           @close="modalSaveAndExit"
    >
      <FormElement>
        <!-- POSITION -->
        <div class="row">
          <div class="col-12  col-xs-24">
            <InputSelect name="language"
                         base="userCv"
                         :class="{ '-has-error': $v.entryToEdit.language.$error }"
                         :options="$t('userCv.inputs.language.options')"
                         v-model="$v.entryToEdit.language.$model">
              <option disabled selected value="">{{ $t('userCv.inputs.language.placeholder') }}</option>
            </InputSelect>
          </div>
          <div class="col-12  col-xs-24  mt-xs-s">
            <InputSelect name="employment"
                         base="userCv"
                         :class="{ '-has-error': $v.entryToEdit.employment.$error }"
                         :options="$t('userCv.inputs.employment.options')"
                         v-model="$v.entryToEdit.employment.$model">
              <option disabled selected value="">{{ $t('userCv.inputs.employment.placeholder') }}</option>
            </InputSelect>
          </div>
        </div>
        <div class="row  mt-s" v-if="entryToEdit.employment === 'mandate'">
          <div class="col-24">
            <InputText name="mandate_title"
                       base="userCv"
                       type="text"
                       v-model="$v.entryToEdit.mandate_title.$model"
                       :class="{ '-has-error': $v.entryToEdit.mandate_title.$error }"
            />
          </div>
        </div>
        <div class="row  mt-s">
          <div class="col-24">
            <InputText name="company"
                       base="userCv"
                       type="text"
                       v-model="$v.entryToEdit.company.$model"
                       :class="{ '-has-error': $v.entryToEdit.company.$error }"
                       :placeholderOverride="emptyCv ? $t('userCv.inputs.company.placeholder-first') : null"
                       autocomplete="organization"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12  col-xs-24  mt-s">
            <InputText name="company_size"
                       base="userCv"
                       type="number"
                       min="0"
                       v-model="$v.entryToEdit.company_size.$model"
                       :empty-value="null"
                       :class="{ '-has-error': $v.entryToEdit.company_size.$error }"
            />
          </div>
          <div class="col-12  col-xs-24  mt-s">
            <InputText name="company_revenue"
                       base="userCv"
                       type="number"
                       v-model="$v.entryToEdit.company_revenue.$model"
                       :empty-value="null"
                       :class="{ '-has-error': $v.entryToEdit.company_revenue.$error }"
            />
          </div>
        </div>
        <div class="row  mt-s">
          <div class="col-24">
            <InputTextarea name="company_description"
                           base="userCv"
                           :rows="2"
                           :class="{ '-has-error': $v.entryToEdit.company_description.$error }"
                           v-model="$v.entryToEdit.company_description.$model"
                           :placeholder="$t('userCv.inputs.company_description.placeholder')"
            />
          </div>
        </div>
        <div class="row mt-s">
          <div class="col-24">
            <InputText name="position"
                       base="userCv"
                       type="text"
                       v-model="$v.entryToEdit.position.$model"
                       :class="{ '-has-error': $v.entryToEdit.position.$error }"
                       :placeholderOverride="emptyCv ? $t('userCv.inputs.position.placeholder-first') : null"
                       autocomplete="organization-title"
            />
          </div>
        </div>
        <div class="row mt-s">
          <div class="col-24 col-xs-24 col-sm-24 col-md-24 col-lg-8">
            <InputText name="location"
                       base="userCv"
                       type="text"
                       v-model="$v.entryToEdit.location.$model"
                       :class="{ '-has-error': $v.entryToEdit.location.$error }"
                       :placeholderOverride="emptyCv ? $t('userCv.inputs.location.placeholder-first') : null"
                       autocomplete="address-level2"
            />
          </div>
          <div class="col-12 col-xs-24 mt-s">
            <InputText name="start"
                       base="userCv"
                       type="date"
                       v-model="$v.entryToEdit.start.$model"
                       :class="{ '-has-error': $v.entryToEdit.start.$error }"
            />
          </div>
          <div class="col-12 col-xs-24 mt-s">
            <InputText name="end"
                       base="userCv"
                       type="date"
                       v-model="$v.entryToEdit.end.$model"
                       :class="{ '-has-error': $v.entryToEdit.end.$error }"
            />
          </div>
        </div>
        <!-- DESCRIPTION -->
        <div class="row mt-xs description">
          <div class="col-24 mb-xxs font-size-xl font-weight-medium">
            {{ $t('userCv.subheadlines.description') }}
          </div>
          <div class="col-24">
            <InputRichText name="description"
                           :class="{ '-has-error': $v.entryToEdit.description.$error }"
                           v-model="$v.entryToEdit.description.$model"
                           ref="description"
                           :placeholder="emptyCv ? $t('userCv.inputs.description.placeholder-first') : $t('userCv.inputs.description.placeholder')"
            />
          </div>
        </div>
        <!-- PDF -->
        <div class="row mt-xs" v-if="role === 'admin'">
          <div class="col-24 mb-xxs font-size-xl font-weight-medium">
            {{ $t('userCv.subheadlines.visibility-pdf-export') }}
          </div>
          <div class="col-24">
            <InputCheckbox name="show_in_pdf"
                           base="userCv"
                           v-model="$v.entryToEdit.show_in_pdf.$model"
            />
            <InputCheckbox name="only_admin"
                           base="userCv"
                           v-model="$v.entryToEdit.only_admin.$model"
            />
          </div>
        </div>
      </FormElement>
      <template slot="footer">
        <div class="modal-buttons">
          <div class="left">
            <Button class="-compact -red -outlined mr-s" @click.native="modalDelete" v-if="$v.entryToEdit.id.$model !== null">
              <Icon class="-inline -is-left">
                <TrashSVG/>
              </Icon>
              {{ $t('userCv.buttons.delete') }}
            </Button>
          </div>
          <div class="right">
            <Button class="-compact -orange -outlined mr-s" @click.native="modalCancel">
              {{ $t('userCv.buttons.cancel') }}
            </Button>
            <Button class="-compact -orange" @click.native="modalSaveAndExit">
              {{ $v.entryToEdit.id.$model === null ? $t('userCv.buttons.add') : $t('userCv.buttons.save') }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import _ from 'lodash';
import {
  required,
  requiredIf,
  minValue,
  maxLength,
  helpers,
} from 'vuelidate/lib/validators';
import { cvEntryAdd, cvEntryUpdate, cvEntryDelete } from '@/api/users.api';

import Icon from '@/components/atoms/Icon.vue';
import Button from '@/components/atoms/Button.vue';
import Link from '@/components/atoms/Link.vue';

import ContentBox from '@/components/elements/ContentBox.vue';
import Modal from '@/components/elements/Modal.vue';
import FormElement from '@/components/elements/FormElement.vue';

import InputText from '@/components/elements/inputs/InputText.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputRichText from '@/components/elements/inputs/InputRichText.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputTextarea from '@/components/elements/inputs/InputTextarea.vue';

import CalendarSVG from '@/assets/icons/calendar.svg';
import ChevronSVG from '@/assets/icons/chevron.svg';
import EditSVG from '@/assets/icons/edit.svg';
import InfoArrowSVG from '@/assets/icons/info-arrow.svg';
import PlaceSVG from '@/assets/icons/place.svg';
import PlusSVG from '@/assets/icons/plus.svg';
import TrashSVG from '@/assets/icons/trash.svg';

const initialLoad = 3;
const maxLengthDescription = 20000;
const blankEntry = {
  id: null,
  company: null,
  company_size: null,
  company_revenue: null,
  company_description: null,
  position: null,
  location: null,
  start: null,
  end: null,
  description: null,
  show_in_pdf: true,
  only_admin: false,
  language: null,
  employment: null,
  mandate_title: null,
};
const dateRegex = /^(19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const dateVuelidateRule = helpers.regex('dateRegex', dateRegex);

export default {
  name: 'UserCv',
  mixins: [validationMixin],
  props: {
    cv_entries: Array,
    uuid: String,
    oldCvLink: Boolean,
  },
  data() {
    return {
      displayingAll: false,
      entryToEdit: {
        ...blankEntry,
        only_admin: this.role === 'admin',
      },
      showLanguage: this.$i18n.locale,
    };
  },
  computed: {
    ...mapState(['role', 'group']),

    emptyCv() {
      return this.cv_entries.length === 0;
    },

    blankEntry() {
      return blankEntry;
    },

    moreAvailable() {
      return this.cv_entries.length > initialLoad;
    },

    displayShowMore() {
      return this.moreAvailable && !this.displayingAll;
    },

    displayShowLess() {
      return this.moreAvailable && this.displayingAll;
    },
    entriesToShow() {
      if (this.displayingAll) {
        return this.cv_entries.length;
      }

      return initialLoad;
    },
  },
  validations: {
    entryToEdit: {
      id: {},
      company: {
        required,
      },
      company_size: {
        minValue: minValue(0),
      },
      company_revenue: {},
      company_description: {},
      position: {
        required,
      },
      location: {},
      start: {
        required,
        dateVuelidateRule,
      },
      end: {
        dateVuelidateRule,
      },
      description: {
        required: requiredIf(function isGroupDelivery() {
          return !this.group.includes('delivery') && !this.group.includes('delivery-admin');
        }),
        maxLength: maxLength(maxLengthDescription),
      },
      show_in_pdf: {},
      only_admin: {},
      language: {
        required,
      },
      employment: {
        required,
      },
      mandate_title: {},
    },
  },
  methods: {
    sortedCv(entries, amount) {
      const entriesCloned = _.clone(entries);

      return entriesCloned
        .filter((el) => el.language === this.showLanguage)
        .sort((firstItem, secondItem) => {
          const firstEnd = (!firstItem.end) ? new Date() : new Date(firstItem.end);
          const secondEnd = (!secondItem.end) ? new Date() : new Date(secondItem.end);

          if (secondEnd > firstEnd) {
            return 1;
          }

          if (secondEnd < firstEnd) {
            return -1;
          }

          return new Date(secondItem.start) - new Date(firstItem.start);
        })
        .slice(0, amount);
    },

    canEdit() {
      return this.userCan('edit', this.uuid) || this.userCan('updateManagerCv');
    },

    showAll() {
      this.displayingAll = true;
    },

    showLess() {
      this.displayingAll = false;
    },

    dateToLocaleMonthAndYear(date) {
      try {
        const dateObj = new Date(date);
        const month = dateObj.toLocaleString(this.$route.params.lang ?? 'de', { month: 'long' });
        const year = dateObj.getFullYear();

        return `${month} ${year}`;
      } catch (e) {
        return date;
      }
    },

    resetEntryToEdit() {
      this.$set(this, 'entryToEdit', { ...blankEntry });

      this.$v.$reset();
    },

    modalOpen(entry = {}) {
      this.$set(this, 'entryToEdit', {
        ...blankEntry,
        only_admin: this.role === 'admin',
        ...entry,
      });

      this.$eventBus.$emit('open-modal-userCvModal');

      this.$v.$reset();

      if (this.$refs.description && this.$refs.description.editor) {
        this.$refs.description.reBuildEditor();
      }
    },

    modalClose() {
      if (this.$refs.description && this.$refs.description.editor) {
        this.$refs.description.destroyEditor();
      }

      this.$v.$reset();

      this.$eventBus.$emit('close-modal-userCvModal', true);
    },

    modalCancel() {
      this.resetEntryToEdit();

      this.modalClose();
    },

    entryToEditToRequestObject() {
      const filtered = _.pick(this.entryToEdit, Object.keys(blankEntry));

      if (!filtered.end) filtered.end = null;

      return filtered.id === null ? _.omit(filtered, ['id']) : filtered;
    },

    modalSaveAndExit() {
      this.$v.$touch();

      if (!this.$v.$invalid && this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            const requestObject = this.entryToEditToRequestObject();

            if (!Object.keys(requestObject).includes('id')) {
              return cvEntryAdd(this.uuid, requestObject, token);
            }

            return cvEntryUpdate(this.uuid, requestObject, token);
          })
          .then(() => {
            this.$eventBus.$emit('notificate');
            this.$emit('update');
            this.resetEntryToEdit();
            this.modalClose();
          });
      }
    },

    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t(`userCv.actions.${action}`)}?`);
    },

    modalDelete() {
      if (!this.confirmAction('deletePositionPermanent')) {
        return;
      }

      if (this.canEdit) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => cvEntryDelete(this.uuid, this.entryToEdit.id, token))
          .then(() => {
            this.$eventBus.$emit('notificate');
            this.$emit('update');
            this.resetEntryToEdit();
            this.modalClose();
          });
      }
    },
  },
  components: {
    InputCheckbox,
    InputText,
    InputRichText,
    InputSelect,
    InputTextarea,
    FormElement,
    Modal,
    Button,
    CalendarSVG,
    ContentBox,
    ChevronSVG,
    EditSVG,
    // EditorContent,
    TrashSVG,
    Link,
    Icon,
    InfoArrowSVG,
    PlaceSVG,
    PlusSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-cv.scss"></style>
